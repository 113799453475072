/* fix for iOS */
a[type="button"] {
  -webkit-appearance: none;
}

/* offers */
.ui.card.offer .content:first-child {
  background-color: #ebf5ed;
}
.ui.card.offer .content.extra {
  background-color: #deffe0;
}

.ui.modal .ui.card.offer {
  min-height: 20vh;
}

.binorange, i.icon.binorange {
  color: #ff783c !important;
}
.atmgreen, i.icon.atmgreen {
  color: #5fac62 !important;
}
.alert {
  color: #ff0000 !important;
}

.narrow-list .header, .narrow-list .description {
  overflow: hidden;
  text-overflow: ellipsis;
}

.narrow-list .item > .content *:not(:last-child) {
  margin-bottom: .5em;
}

.ui.list.narrow-list {
  margin-bottom: 0;
}

/* TODO fix this hack */
.header-menu {
  background-color: #f0f5f0 !important;
}

@media only screen and (max-width: 767px) {
  .header-menu .ui.container {
    margin-left: .57142857em !important;
    margin-right: .57142857em !important;
  }
  .ui.vertical.menu.header-menu {
    width: auto;
  }
}

.header-menu:not(.vertical) .grid .column {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.menu.header-submenu {
  border-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0;
}
.header-menu .item, .header-submenu .item {
  font-weight: 500 !important;
  text-transform: uppercase !important;
  color: rgba(0,0,0,.87) !important;
}
.menu.header-menu:not(.vertical) .item {
  text-align: center;
  padding: 0 !important;
  margin-right: 0 !important;
  margin-left: .71428572em !important;
}
.menu.header-menu:not(.vertical) .item:not(:last-child)::after {
  content: '';
  height: 2em;
  width: .71428572em;
  border-right: 1px solid #f67643;
}
.header-menu .item:not(.header):hover, .header-menu .item.active {
  color: #22aa86 !important;
  background: transparent !important;
}

.header-submenu .item {
  color: rgba(255,255,255,.8) !important;
}
.header-submenu .item:not(.header):hover, .header-submenu .item.active {
  color: white !important;
}
.header-admin-menu {
  background-color: grey !important;
  margin: 0 !important;
}
.header-user-menu {
  background-color: #89B6A5 !important;
  margin: 0 !important;
}
.header-user-menu .item {
  font-weight: bold !important;
}

.header-not-logged-menu {
  background-color: #89B6A5  !important;
  margin: 0 !important;
}

.signup-bt {
  background-color: #04415F !important;
  color: #fff !important;
}

.signup-bt:hover {
  background-color: #135374 !important;
}

.header-title {
  color: white !important;
  background-color: #89B6A5 !important;
}
.ui.segment.header-title {
  margin-bottom: 0;
}

:root {
  --header-height: 18rem;
  --footer-height: 22rem;
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 991px) {
  :root {
    --header-height: 24rem;
    --footer-height: calc(22rem + 13rem);
  }
}

/* mobile */
@media screen and (max-width: 768px) {
  :root {
    --header-height: 23rem;
    --footer-height: calc(22rem + 36rem);
  }
}

.header-content {
  height: var(--header-height);
}

.footer-menu {
  height: var(--footer-height);
  background-color: #4c5953 !important;
  margin: 0 !important;
}

main {
  min-height: calc(100vh - var(--footer-height) - var(--header-height));
  /* WTF */
  padding: 1px 0; 
}


/* fix sticky showing on bottom of page in mobile dashboard view */
/* this is needed to fix erratic behavior of Sticky stuff if they are part of children */
/* see https://github.com/Semantic-Org/Semantic-UI-React/issues/2897 */
.pushable {
  transform: none !important;
}
.ui.bound.sticky {
  display: none;
}


.ui.grid.stackable.compact:not(.stretched) .column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ui.grid.stackable.compact.stretched .column {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ui.grid.stackable.compact.stretched {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.orange.divider {
  background-color: #F67643 !important;
  border-top: 5px solid #F67643 !important;
  border-bottom: 4px solid #F67643 !important;
  margin-bottom: -5px;
  z-index: 1;
  position: relative;
}

.fat.divider {
  background-color: #707070 !important;
  border-color: #707070 !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.ui.header.action-header {
  color: white !important;
  text-transform: uppercase;
  /* padding-left: 2rem; */
  border-width: 0px !important;
  border-radius: 5px;
  display: block;
}
.ui.header.action-header .icon {
  float: right;
  margin: 0;
}
a.ui.header.action-header:hover {
  color: white !important;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}
.action-header.blue {
  background-color: #2088be !important;
}
.action-header.green {
  background-color: #5fac62 !important;
}
.action-header.orange {
  background-color: #ff783c !important;
}

.ui.segment.attached.colored {
  border-width: 0;
}
.ui.segment.lightgreen {
  background-color: #edf5ed;
}
.ui.segment.brightgreen {
  background-color: #6cc46f;
}

.ui.list.decorated > .item > span {
  display: inline-block;
  margin-left: 1em;
}
.ui.list.decorated.bulleted > .item::before {
  font-size: x-large;
}

.ui.list.decorated:not(.bulleted) {
  margin-left: 1em;
}
.ui.list.decorated:not(.bulleted) > .item::before {
  vertical-align: top;
  margin-left: -1em;
}

.ui.list.green > .item::before {
  color: #5fac62;
}
.ui.list.orange > .item::before {
  color: #ef8048;
}
.ui.list.red > .item::before {
  color: #c91010;
}

.ui.list.icon > .item::before {
  font-size: larger;
	font-family: Icons;
	font-style: normal;
	font-weight: 400;
	text-align: center;
}
.ui.list.icon.check > .item::before {
  content: '\f00c';
}
.ui.list.icon.close > .item::before {
  content: '\f00d';
}

.ui.header.colored {
  text-transform: uppercase;
}

.ui.segment.small .ui.image {
  height: 30vh;
}


.ui.accordion.green .title {
  color: #4d4d4d;
  background-color: #e0e0e0;
  margin: 2px 0;

  padding: .75em 3em .75em 2em;

  line-height: 1.25;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
}

.ui.accordion.green .title.active {
  color: white;
  background-color: #5fac62;
}

.ui.accordion.green .title .icon:first-child {
  display: none;
}

.ui.accordion.green .title::before {
  content: '\f078';
  font-family: Icons;
  font-size: x-large;

  position: absolute;
  right: 1em;
}

.ui.accordion.green .title.active::before {
  content: '\f077';
}

.ui.statistics.centered .ui.statistic {
  margin-left: auto;
  margin-right: auto;
}

.green-icon {
  color: #72f3b9;
}

.green-icon.t::after {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bolder;
  content: 'T'
}

.green-icon.f::after {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bolder;
  content: 'F'
}

.footer-header {
  text-transform: uppercase !important;
  font-weight: 400 !important;
  position: relative;
  margin-bottom: 24px !important;
}

.footer-header::before {
	background-color: #22AA86;
	content: '';
	width: 48px;
	height: 2px;
	border-bottom: 2px;
	text-align: inherit;
	bottom: -12px;
	position: absolute;
}

.footer-header::after {
  content: '';
	width: calc(100% - 52px);
	height: 2px;
	border-bottom: 2px solid rgba(255,255,255,.16);
	text-align: inherit;
	bottom: -12px;
	position: absolute;
	right: 0;
}

.footer-links a {
  text-transform: uppercase !important;
  font-weight: 400;
}

.ui.inverted.list.footer-links  {
	color: rgba(255,255,255,.90) !important;
}

.ui.inverted.list.footer-links .item a:not(.ui):hover {
	color: #fff !important;
}

.ui.list.decimal > .item:before {
  content: counters(ordered, ".") ". ";
}
.ui.list.decimal .list > .item:before {
  margin-left: -1.25rem;
}
.ui.list.bulleted > .item:before {
  content: "\2022" !important;
  /* margin-left: 1.25rem !important; */
}

.list.plain {
  padding-top: 0 !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
}
.list.plain > .item::before {
  content: '' !important;
}

.dashlist {
  list-style-type: '- ';
  list-style-position: inside;
  padding: 0;
}

.ck-content img {
  max-width: 100%;
}

.ck-content .image {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.ck-content .image-style-side {
  float: right;
  margin-right: 1.5em;
  max-width: 50%;
}

.ui.vertical.pointing.menu .item {
  text-align: center;
  line-height: inherit !important;
  padding: .92857143em .78571429em !important;
}

.ui.vertical.pointing.pagination.menu .item {
  padding: .78571429em .78571429em !important;
}

.ui.vertical.left.pointing.menu .item:after {
  right: auto;
  left: 0;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  margin: 0 0 0 -.5px;
  border: none;
  border-bottom: 1px solid #d4d4d5;
  border-left: 1px solid #d4d4d5;
}

.ui.vertical.left.pointing.menu .item.disabled.active {
  background-color: rgba(0, 0, 0, .05) !important;
}

.ui.vertical.menu .item > i.icon {
  float: none !important;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .ui.compact.grid {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .ui.compact.grid > .column:not(.row) {
    padding: 3px 1rem !important;
  }
  .ui.menu {
    min-height: auto;
  }
}

.ui.compact.menu .item:last-child {
  border-radius: 0;
}

.ui.selectable.pointable.table tbody tr:hover {
  cursor: pointer;
}

/* Webfont: Lato-Bold */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-BoldItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Italic.woff2') format('woff2');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-MediumItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}
